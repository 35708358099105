// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS for the body element */
body {
  margin: 0;  /* Resets the default margin of the body element to 0 to remove browser default spacing */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; /* Sets a stack of preferred fonts for text, starting with system defaults and providing fallbacks */
  -webkit-font-smoothing: antialiased; /* Enhances the font rendering on Webkit browsers like Chrome and Safari to make text crisper */
  -moz-osx-font-smoothing: grayscale; /* Applies a grayscale antialiasing to text in Firefox on macOS for clearer font rendering */
}

/* CSS for elements using the <code> tag */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; /* Sets a stack of preferred monospaced fonts for displaying code, providing multiple fallbacks */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,SAAS,GAAG,yFAAyF;EACrG;;cAEY,EAAE,oGAAoG;EAClH,mCAAmC,EAAE,+FAA+F;EACpI,kCAAkC,EAAE,4FAA4F;AAClI;;AAEA,0CAA0C;AAC1C;EACE;aACW,EAAE,iGAAiG;AAChH","sourcesContent":["/* CSS for the body element */\nbody {\n  margin: 0;  /* Resets the default margin of the body element to 0 to remove browser default spacing */\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; /* Sets a stack of preferred fonts for text, starting with system defaults and providing fallbacks */\n  -webkit-font-smoothing: antialiased; /* Enhances the font rendering on Webkit browsers like Chrome and Safari to make text crisper */\n  -moz-osx-font-smoothing: grayscale; /* Applies a grayscale antialiasing to text in Firefox on macOS for clearer font rendering */\n}\n\n/* CSS for elements using the <code> tag */\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace; /* Sets a stack of preferred monospaced fonts for displaying code, providing multiple fallbacks */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
